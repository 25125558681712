:root {
    /* Size of a click target: 7-10mm */
    --unit: 3rem;

    /* Width of a column: 55-70mm (rems shrink to fit 7 units into any screenwidth) */
    --column: calc(var(--unit) * 7);

    /* Width of a screen-wide segment */
    --screen: 100vw;

    /* Height of the `here` part of the screen */
    --fit-height: calc(100vh - 6rem - var(--padding) * 2);

    /* How much the segments can bleed behind each other */
    --bleed: 0.5rem;

    /* spacing around top and bottom of the accordion */
    --padding: 2rem;

    /* Dimensions of a peek */
    --peek-height: 9rem;
    --peek-width: 9rem;
}


:root {
    --animation-duration: .3s;
}
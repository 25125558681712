:root {
  --unit: 3rem;
  --column: calc(var(--unit) * 7);
  --screen: 100vw;
  --fit-height: calc(100vh - 6rem - var(--padding) * 2);
  --bleed: .5rem;
  --padding: 2rem;
  --peek-height: 9rem;
  --peek-width: 9rem;
  --animation-duration: .3s;
}

/*# sourceMappingURL=index.f577325f.css.map */
